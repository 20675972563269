.Modal {
    position: fixed;
    z-index: 500;
    background-color:transparent;
    width: 70%;
    padding: 10px 10px 0 30px;
    left: 15%;
    top: 10%;
    box-sizing: border-box;
    transition: all 0.5s ease-out;
}

.title{
    width: 100%;
    margin: 0;
    padding-bottom: 0px;
    padding-top: 0px;
    text-align: left;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}

@media (max-width: 550px) {
    .title {
        font-size: 24px;
    }
}