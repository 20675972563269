html {
  overflow: visible;
}

.App {
  width: 100%;
  min-width: 720px;
  height: 95vh;
  /* margin-left: auto; 
  margin-right: auto; */
}

.myJumbotron {
  position: relative;
  padding: 1% 0;
  color: #fff;
  background-color: rgb(194, 7, 7);
  text-align: center;
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
}

.titleFont {
  font-size: max(6.25vw, 36px);
  line-height: 5rem;
  letter-spacing: -2px;
  margin: 10% 5%;
  margin-block-start: 0.67rem;
  margin-block-end: 0.2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0,0,0,.4), 0 0 30px rgba(0,0,0,.075);
}

.bottomTitle {
  font-size: max(6.2vw, 32px);
  line-height: 5rem;
  letter-spacing: -2px;
  margin: 10% 5%;
  margin-block-start: 0.67rem;
  margin-block-end: 0.2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
}

.downloadTitle {
  font-size: 4.6vw;
}

.subTitle {
  font-weight: 200;
  line-height: 1.25;
  font-stretch: expanded;
  font-size: max(1.95vw,12px);
}

.subsubTitle {
  font-weight: 350;
  line-height: 1.25;
  font-stretch: expanded;
  font-size: max(1.2vw,12px);
}

.queriesFont {
  font-weight: 350;
  line-height: 1.25;
  font-stretch: expanded;
  font-size: max(.95vw,10px);
}

.bottomText {
  font-weight: 500;
  line-height: 1.25;
  font-stretch: expanded;
  font-size: max(1vw,12px);
  text-shadow: 0 0 1px rgba(0,0,0,.4), 0 0 30px rgba(0,0,0,.075);
}

.caption {
  font-size: max(1.25vw,12px);
  font-weight: 300;
}

.plotImages {
  display: flex;
  width: 60%;
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1900px) {
  .plotImages {
    min-width: 700px;
  }
}
@media (max-width: 1400px) {
  .plotImages {
    width: 80%;
    min-width: 500px;
  }
}

@media (min-width: 1400px) {
  .titleFont {
      font-size: 90px;
  }
  .subTitle{
    font-size: 28px;
  }

  .subsubTitle {
    font-size: 18px;
  }

  .queriesFont {
    font-size: 16px;
  }

  .bottomTitle {
    font-size: 60px;
  }

  .bottomText{
    font-size: 17.5px;
  }

  .caption {
    font-size: 18px;
  }

  .downloadTitle {
    font-size: 55px;
  }
}

@media (max-width: 800px) {
  .titleFont {
    font-size: 50px;
  }
  .subTitle{
    font-size: 16px;
  }
  .downloadTitle {
    font-size: 30px;
  }
}