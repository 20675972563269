.dropdown {
    position: relative;
    display: inline-block;
}
.dropdownUl {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: max(3.5vw, 25px);
    left: 2vw;
    width: 150px;
    font-size: 12px;
    background-color: white;
    font-weight:bold;
    position: absolute;
    box-shadow: 0vw .556vw 1.112vw 0vw rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdownUl li a {
    color: #000;
    text-decoration: none;
    margin: 0;
}
.dropdownUl li {
    padding: .556vw 1.112vw;
    border-bottom: 0.0694vw solid #e5e5e5;
}
.dropdownUl li:last-child {
    border-bottom: none;
}
.dropdownUl li:hover {
    background-color: #e5e5e5;
    color: white;
}
.button1{
    width: 100%;
    min-width: 104px;
    height: 100%;
    min-height: 14px;
    background-color:#006dcc ;
    padding: 1vw 1vw;
    border: 0.0694vw solid #ccc;
    border-radius: 0.34722vw;
    font-weight: bold;
    color: white;
    font-size: max(1.389vw, 12px);
    cursor: pointer;
    text-shadow: 0 -0.0694vw 0 rgba(0,0,0,0.25);
    background-repeat: repeat-x;
    background-image: linear-gradient(to bottom,#08c,#04c);
    border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
    border-radius: 0.4167vw;
    line-height: max(1.389vw, 10px);
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    display: inline-block;
    vertical-align: middle;
    font-weight: inherit;
    padding-left: 0.34722vw;
    padding-right: 1.5vw;
}

.button2{
    width: 100%;
    min-width: 204px;
    height: 100%;
    min-height: 14px;
    background-color:#006dcc ;
    padding: 1vw 1vw;
    border: 0.0694vw solid #ccc;
    border-radius: 0.34722vw;
    font-weight: bold;
    color: white;
    font-size: max(1.389vw, 12px);
    cursor: pointer;
    text-shadow: 0 -0.0694vw 0 rgba(0,0,0,0.25);
    background-repeat: repeat-x;
    background-image: linear-gradient(to bottom,#08c,#04c);
    border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
    border-radius: 0.4167vw;
    line-height: max(1.389vw, 10px);
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    display: inline-block;
    vertical-align: middle;
    font-weight: inherit;
    padding-left: 0.34722vw;
    padding-right: 1.5vw;
}

.arrow {
    border: solid white;
    border-width: 0 0.3vw 0.3vw 0;
    display: inline-block;
    padding: 0.3vw;
    margin-bottom: 0.35vw;
    margin-left: 0.3vw;
}
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

@media (min-width: 1400px) {
    .button1 {
        font-size: 20px;
        width: 178px;
        height: 20px;
        padding-left: 5px;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 12px;
        line-height: 20px;
        text-shadow: 0 -1 0 rgba(0,0,0,0.25);
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 6px;
    }
    .button2 {
        font-size: 20px;
        width: 350px;
        height: 20px;
        padding-left: 5px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 12px;
        line-height: 20px;
        text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 6px;
    }
    .dropdownUl {
        font-size: 14px;
        top: 45px;
        width: 200px;
        box-shadow:  8px 16px 0 rgba(0,0,0,.2)
    }

    .dropdownUl li {
        padding: 8px 16px;
        border-bottom: 1px solid #e5e5e5;
    }
    .arrow {
        border-width: 0 4px 4px 0;
        padding: 5px;
        margin-bottom: 5px;
        margin-left: 10px;
    }
}

.button:hover,
.button:focus,
.button:active,
.button.active,
.button.disabled,
.button[disabled] {
  color: #ffffff;
  background-color: #0044cc;
  *background-color: #003bb3;
}

.button:active,
.button.active {
  background-color: #003399 \9;
}