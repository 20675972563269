.Login{
    text-align: center;
    display: inline;
}

.LoginButton {
    background-color: rgb(223, 23, 23);
    outline: none;
    cursor: pointer;
    border: 1px solid #030303;
    color: #ffffff;
    font-family: inherit;
    font-size: 1.2em;
    padding: 15px 30px;
    box-shadow: 2px 2px 2px #966909;
    text-align: center;
}

.LoginButton:hover, .OrderButton:active {
    background-color: rgb(221, 41, 41);
    border: 1px solid #f1e864;
    color: #f8f7f6;
}

.LoginButton:disabled {
    background-color: #C7C6C6;
    cursor: not-allowed;
    border: 1px solid #ccc;
    color: #888888;
}

/* The Close Button */
.modal_close {
    color: rgb(0, 0, 0);
    float: right;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 5px;
    position: relative;
}

.modal_close:hover {
    cursor: pointer;
}

.formatInputLabel {
    font-size: 15px;
    display:flex; 
    flex-direction: row; 
    justify-content: center; 
    align-items: center;
    padding-left: 10%;
    padding-top: 10px;
}

.inputField {
    margin-left: auto;
    margin-right: 20%;
}

.signInButton {
    font-size: 18px;
    padding: 8px 14px;
}