/* Style the navigation menu */
.myNavbar {
    position: static;
    width: 70%;
    max-width: 1050px;
    height: 100%;
    background-color: black;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .myNavbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: black;
  }
  
  .myNavbar li {
    float: left;
    font-size: max(1.25vw, 12px);
  }
  
  .myNavbar li p, li a {
    display: block;
    color: white;
    text-align: center;
    margin: 1.2vw 50px;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    z-index: 9999;
  }
  
  .myNavbar li:hover, li p:hover, li a:hover {
    color: rgb(43, 144, 238);
    text-decoration: none;
  }

  @media (min-width: 1400px) {
    .myNavbar li {
        font-size: 18px;
    }
    .myNavbar li p, li a {
      margin: 15px 50px;
    }
    .myNavbar {
      height: 50px;
      overflow: hidden;
    }
  
  }